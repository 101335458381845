@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
}
body {
  @apply h-full bg-gray-100;
}

.btn {
  @apply font-medium text-sm py-2 px-3;
}
.btn-gray {
  @apply bg-gray-600 text-white shadow-sm shadow-gray-300;
}
.btn-gray:hover {
  @apply bg-gray-700;
}

.btn-gray:disabled {
  @apply opacity-60 cursor-not-allowed;
}

.btn-light-gray {
  @apply bg-gray-500 text-white shadow-sm shadow-gray-300;
}
.btn-light-gray:hover {
  @apply bg-gray-600;
}

.btn-back-gray {
  @apply bg-gray-400 text-white shadow-sm shadow-gray-300;
}
.btn-back-gray:hover {
  @apply bg-gray-500;
}

.btn-pink {
  @apply font-medium text-sm py-2 px-3 bg-pink-600 text-white shadow-sm shadow-gray-300;
}
.btn-pink:hover {
  @apply bg-pink-700;
}
.btn-pink:disabled {
  @apply bg-opacity-60 cursor-not-allowed;
}
.btn-blue {
  @apply font-medium text-sm py-2 px-3 bg-cyan-600 text-white shadow-sm shadow-gray-300;
}
.btn-blue:hover {
  @apply bg-cyan-700;
}
.btn-blue:disabled {
  @apply bg-opacity-60 cursor-not-allowed;
}
.btn-yellow {
  @apply font-medium text-sm py-2 px-3 bg-amber-400 text-white shadow-sm shadow-gray-300;
}
.btn-yellow:hover {
  @apply bg-amber-500;
}
.btn-yellow:disabled {
  @apply bg-opacity-60 cursor-not-allowed;
}
.btn-green {
  @apply font-medium text-sm py-2 px-7  bg-lime-500 text-white shadow-sm shadow-gray-300;
}
.btn-green:hover {
  @apply bg-lime-600
  /* bg-[#BEE297]; */;
}
.btn-green:disabled {
  @apply bg-opacity-60 cursor-not-allowed;
}

.btn-red {
  @apply bg-red-600 text-white shadow-sm shadow-red-200;
}
.btn-red:hover {
  @apply bg-red-700;
}

.customer-browse-btn {
  @apply font-medium text-sm py-2 px-3 bg-gray-600 text-white;
}

.customer-browse-btn:hover {
  @apply bg-gray-500;
}

.class-text-md {
  @layer text-md;
}

.input-field-label {
  @apply block class-text-md font-light leading-6 text-gray-900;
}

.input-field {
  @apply block w-full  border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-[1.6rem];
}

.custom-input-field {
  @apply input-field focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-300 sm:text-sm !py-0 sm:leading-[1rem];
}

.input-field:disabled {
  @apply opacity-60;
}

.checkbox-field {
  @apply cursor-pointer text-pink-500 outline-0 ring-0 focus:ring-0;
}
.checkbox-field:disabled {
  @apply cursor-not-allowed opacity-60;
}

.file-field {
  @apply relative m-0 block w-full min-w-0 flex-auto border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:text-neutral-700  focus:outline-none;
  /* focus:border-primary focus:shadow-te-primary */
}
.post .file-field {
  @apply relative m-0 block w-full min-w-0 flex-auto border bg-white border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-300 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-400 focus:text-neutral-700 hover:file:cursor-pointer hover:cursor-pointer  focus:outline-none;
  /* focus:border-primary focus:shadow-te-primary */
}

.icon-btn {
  @apply p-1 bg-white shadow hover:bg-slate-50 cursor-pointer;
}

.icon-btn:disabled {
  @apply cursor-not-allowed opacity-60 hover:bg-white;
}

.disabled-btn {
  @apply cursor-not-allowed opacity-60;
}

.post-editor-btn {
  @apply w-12 h-12 flex justify-center items-center shadow text-gray-600 border border-gray-500 cursor-pointer hover:border-gray-400;
}
.post-editor-btn:disabled {
  @apply cursor-not-allowed opacity-60 border-gray-200;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
} */

.direct-mail-camp:hover svg path {
  fill: white;
}
.direct-mail-camp:hover {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white border border-[#9CA38F];
}
.direct-mail-camp {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.direct-mail-camp.active {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white;
}
.direct-mail-camp.active svg path {
  fill: white;
  color: white;
}

.digital-offer-camp svg path {
  fill: #757576;
}
.digital-offer-camp:hover svg path {
  fill: #fff;
}
.digital-offer-camp:hover {
  @apply bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-white border border-[#9CA38F];
}
.digital-offer-camp {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.digital-offer-camp.active {
  @apply bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-white;
}
.digital-offer-camp.active svg path {
  fill: white;
  color: white;
}

.print-ad-camp svg path {
  fill: #757576;
}
.print-ad-camp:hover svg path {
  fill: #fff;
}
.print-ad-camp:hover {
  @apply bg-gradient-to-b from-[#f35288] to-[#f186ac] text-white border border-[#9CA38F];
}
.print-ad-camp {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.print-ad-camp.active {
  @apply bg-gradient-to-b from-[#f35288] to-[#f186ac] text-white;
}
.print-ad-camp.active svg path {
  fill: white;
  color: white;
}

.email-camp svg path {
  stroke: #757576;
}
.email-camp:hover svg path {
  stroke: #fff;
}
.email-camp svg line {
  stroke: #757576;
}
.email-camp:hover svg line {
  stroke: #fff;
}
.email-camp:hover {
  @apply bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-white border border-[#9CA38F];
}
.email-camp {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.email-camp.active {
  @apply bg-gradient-to-b from-[#37d6eb] to-[#B5E4FA] text-white;
}
.email-camp.active svg path {
  fill: white;
  color: white;
}

.sms-camp svg path {
  fill: #757576;
}
.sms-camp:hover svg path {
  fill: #fff;
}
.sms-camp:hover {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white border border-[#9CA38F];
}
.sms-camp {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.sms-camp.active {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white;
}
.sms-camp.active svg path {
  fill: white;
  color: white;
}

.free-wifi svg path {
  fill: #000;
}
.free-wifi:hover svg path {
  fill: #fff;
}
.free-wifi:hover {
  @apply bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-white border border-[#9CA38F];
}
.free-wifi {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.free-wifi.active {
  @apply bg-gradient-to-b from-[#ffd25f] to-[#fdf186] text-white;
}
.free-wifi.active svg path {
  fill: white;
  color: white;
}

.compliance-ada:hover svg path {
  fill: white;
}
.compliance-ada:hover {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white border border-[#9CA38F];
}
.compliance-ada {
  @apply bg-gradient-to-b from-[#d5d5d5] to-[#eeeeef] border border-[#9CA38F];
}
.compliance-ada.active {
  @apply bg-gradient-to-b from-[#a5d583] to-[#dcf1af] text-white;
}
.compliance-ada.active svg path {
  fill: white;
  color: white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.editorClassName {
  min-height: 150px;
  padding-left: 4px;
  padding-right: 4px;
}

.wrapperClassName {
  box-sizing: border-box;
  border: 1px solid #c7c5c5;
}

.remove-focus-ring {
  @apply text-pink-600 focus:ring-0;
}

.apexcharts-legend-marker {
  top: 0 !important;
}

.graph-title-background-color {
  @apply bg-gradient-to-r from-[#ffd25f] to-[#fdf186];
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-slide video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-pagination {
  display: none;
}
.swiper-button-next,
.swiper-button-prev {
  display: none;
}

.menu-items-gallery .swiper {
  width: 100%;
  height: 100%;
}

.menu-items-gallery .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-items-gallery .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-list .ql-editor {
  padding: 0;
}
.post-list .ql-container.ql-snow {
  border: none;
}
