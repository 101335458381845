.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 6px;
  margin-left: 10px;
}

/* Track */
.custom-scrollbar ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: #bcbfc4;
}

/* Handle on hover */
.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #b5b8bd;
}

/* Up and down arrows */
.custom-scrollbar ::-webkit-scrollbar-button:start:decrement,
.custom-scrollbar ::-webkit-scrollbar-button:end:increment {
  display: block;
  background: #f1f1f1;
}

/* Up arrow */
.custom-scrollbar ::-webkit-scrollbar-button:start:decrement {
  width: 100%;
  height: 8px;
}

/* Down arrow */
.custom-scrollbar ::-webkit-scrollbar-button:end:increment {
  width: 100%;
  height: 8px;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.import {
  position: relative;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  from {
    top: 0px;
  }
  to {
    top: 50px;
  }
}

/* textarea animation  */

.inner-div {
  padding: 10px;
  margin: 20px 0px;
  overflow: hidden;
}

.open {
  animation: openShutter 0.7s ease-out 1 forwards;
}

.close {
  animation: closeShutter 0.7s ease-in-out 1 forwards;
}

.outer-div {
  transition: all 3s ease-in-out;
}

@keyframes openShutter {
  0% {
    height: -2px;
    padding: 0;
    margin: 0;
    opacity: 0;
  }

  100% {
    height: auto;
    margin: 0;
    opacity: 1;
  }
}

@keyframes closeShutter {
  0% {
    height: auto;
    padding: 8px;
    margin: 0px 0px;
    opacity: 0.7;
  }

  100% {
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
  }
}

.css-13cymwt-control {
  border-radius: 0 !important;
}

/* .select__input {
  visibility: hidden;
}

.css-1xc3v61-indicatorContainer {
  cursor: pointer;
} */
/* For contacts > details > add tag dropdown outlines */
